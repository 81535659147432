.header {
    text-align: right;
    position: fixed;
    width: 100%;
    z-index: 999;
}

.header ul {
    list-style: none;
    padding: 0;
    padding-right: 0;
}

.header ul li {
    display: inline-block;
    margin-right: 50px;
    line-height: 48px;
}

.header ul li a {
    text-decoration: none;
    color: white;
    font-weight: 500;
    font-size: 18px;
}

.home-logo-small {
    width: 200px;
    height: 61px;
    margin-left: 30px;
    margin-top: 10px;
    background-image: url('/src/assets/img/WELLEAT_white-43.png');
}

.navbar {
    display: flex;
    justify-content: space-between;
    height: 80px;
}

.navbar.active {
    background-color: #f2f2f1;
    color: black;
}

.navbar.active a {
    font-weight: 600;
    color: black;
}

.navbar.active .home-logo-small {
    background-image: url('/src/assets/img/WELLEAT_TEXT-41.png');
}