.modalContainer {
    position: fixed;
    display: flex;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transform: scale(0.8);
    transition: opacity 0.3s, visibility 0.5s, transform 0.5s;
}

.modalContainerActive {
    position: fixed;
    display: flex;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    visibility: visible;
    opacity: 1;
    transform: scale(1);
    /* background-color: rgba(128, 128, 128, 0.336); */
}

.modalReview {
    width: 540px;
    height: 726px;
    background-color: #FFFFFF;
    position: relative;
    border-radius: 6pt;
    box-shadow: 0px 8px 16px 0px #00000040;

}

.modalClose {
    position: absolute;
    top: 15px;
    right: 20px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    background-image: url(./../../assets/img/close-button.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.modalReviewHeader {
    width: 540px;
    height: 64px;
    background-color: #F5F5F5;
    text-align: left;
    line-height: 64px;
    border-top-left-radius: 6pt;
    border-top-right-radius: 6pt;
}

.modalReviewHeader h3 {
    color: black;
    margin: 0;
    padding: 0;
    margin-left: 24px;
    text-transform: uppercase;
}

.modalReviewBody {
    height: 588px;
    background-color: #FFFFFF;
    text-align: left;
    padding: 20px 50px 0 50px;
}

.modalReviewInput label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 10px;
    color: rgba(0, 0, 0, 0.6);
}

.modalReviewInput {
    font-size: 18px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    width: 100%;
}


.modalReviewInput input {
    height: 30px;
    border: 1px solid #0000001F;
    border-radius: 6pt;
    width: 100%;
    margin-bottom: 10px;

}

.radioModalReview {
    display: flex;
    flex-direction: row;
}

.radioModalReviewInput {
    height: 20px;
    width: 30px;
    background-color: #FFC959;
}

.radioModalReview label {
    margin-right: 50px;
}

.modalReviewTextArea label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 10px;
    color: rgba(0, 0, 0, 0.6);
}

.modalReviewTextArea textarea {
    border: 1px solid #0000001F;
    border-radius: 6pt;
    width: 100%;
    margin-bottom: 10px;
}

.modalReviewPicture {}

.modalReviewPicture label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 10px;
    color: rgba(0, 0, 0, 0.6);
}

.UserReviewPictureTemp {
    background-image: url(./../../assets/img/UserReviewPictureTemp.png);
    height: 120px;
    width: 120px;
    background-size: contain;
    margin: auto;
}

.modalReviewFooter {
    width: 540px;
    height: 74px;
    background-color: #F5F5F5;
    border-bottom-left-radius: 6pt;
    border-bottom-right-radius: 6pt;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.addUserReview {
    width: 110px;
    height: 42px;
    background: #FFC959;
    border-radius: 4px;
    border: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    margin-right: 20px;
}