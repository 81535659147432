#admin {
    display: flex;
}

/* .AHeader {
    text-align: center;
    line-height: 50px;
    background-color: grey;
}

.SideMenu {
    flex-grow: 1;
    border-right: solid 1px grey;
} */

/* #admin-body {
    flex-grow: 6;
    padding-left: 15px;
} */

/* ************************* */

.SideMenu ul {
    list-style: none;
}

.SideMenu ul li a {
    text-decoration: none;
    color: black;
}