.app-content {
    font-family: 'Inter', sans-serif;

    /* --sub-content-background: linear-gradient(0deg, rgba(43, 43, 42, 1) 36%, rgba(255, 201, 89, 1) 98%, rgba(255, 227, 90, 1) 100%); */
    --sub-content-background: radial-gradient(circle farthest-corner at 50% -120%, rgba(255, 227, 90, 1) 0%, rgba(255, 201, 89, 1) 0%, rgba(43, 43, 42, 1) 72%);

    background: var(--sub-content-background);
    background-attachment: fixed;
    overflow-y: 'scroll';
    scroll-snap-type: y mandatory;
}

.app-content.active {
    --sub-content-background: radial-gradient(circle farthest-corner at 50% -120%, rgba(0, 200, 151, 1) 0%, rgba(0, 200, 151, 1) 0%, rgba(43, 43, 42, 1) 70%);

    background: var(--sub-content-background);
    background-attachment: fixed;
    overflow-y: 'scroll';
    scroll-snap-type: y mandatory;
}

/* -------------------- */
/*HOME*/

.home-container {
    padding: 16% 0;
    width: auto;
    height: auto;
}


.home-logo {
    width: 250px;
    height: 249px;
}

h1 {
    color: white;
}

h3 {
    color: white;
    text-align: left;

}

/* -------------------- */
/*MENTIONS*/

.mentions-container {
    --sub-content-background: radial-gradient(circle farthest-corner at 50% -120%, rgba(255, 227, 90, 1) 0%, rgba(255, 201, 89, 1) 0%, rgba(43, 43, 42, 1) 72%);

    background: var(--sub-content-background);
    background-attachment: fixed;
    overflow: 'scroll';
    height: auto;
}

.mentions-content {
    padding: 100px 0 100px 0;
}

/* -------------------- */
/*APPLI ACTEUR*/
.appli-acteur {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}



.header-appli-acteur {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

}



.header-appli-acteur h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    /* line-height: 103px; */
    text-align: center;
    color: #FFC959;
}

.header-appli-acteur h3 {
    color: black;
    text-align: center;
    font-weight: 700;
    font-size: 24px;
}

.header-appli-acteur h4 {
    color: black;
    text-align: center;
    font-weight: 700;
    font-size: 28px;
}

.appli-acteur-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.logo-appli-acteur {
    background-color: #FFC959;
    height: 161px;
    width: 161px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
}

.logo-appli-acteur-carrousel-content {
    padding: 0 20%;
}

.chefLogo {
    height: 100px;
    width: 100px;
}

.appli-acteur-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    text-align: center;
    color: #FFC959;
    margin-top: 80px;
}

.appli-acteur-subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    text-align: left;
    color: #271a00;
    margin-top: 80px;
}


.logo-appli-acteur-carrousel {
    height: 150px;
    width: 100%;
    background-color: black;
}



/* -------------------- */
/*APPLI CONSO*/