.header-dashboard {
    margin: 0;
    padding: 0;
    width: 300px;
    background-color: #00C897;
    /* background-image: url("./../../assets/img/splash_empty_1024x1024.png");
    background-size: cover;
    background-repeat: no-repeat; */
}

.header-dashboard li {
    list-style: none;
    height: 100%;
    width: 100%;
    line-height: 40px;
    font-size: 17px
}

.header-dashboard li:hover {
    background-color: #FFC959;
}

.header-dashboard li a {
    text-decoration: none;
    color: black;
    font-weight: 500;
    display: block;
    height: 100%;
    width: 100%;
}

.header-nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.user-desc {
    display: flex;
    flex-direction: row;
    padding: 20px;
    border-bottom: 2px solid black;
}

.user-desc-ul {
    margin: 0;
    padding: 0;
    margin-top: 20px;
    margin-bottom: 20px;
}

.user-initials {
    width: 54px;
    height: 48px;
    line-height: 48px;
    border: 2px solid black;
    border-radius: 4pt;
    font-weight: 600;
    font-size: 18px;
}

.user-name {
    width: 200px;
    height: 50px;
    font-weight: 700;
    font-size: 18px;
    padding-top: 4px;
}

.ita {
    font-style: italic;
    font-weight: 400;
    text-align: left;
}

.logOutButton {
    margin-top: 50px;
}

.logout {
    font-size: 16px;
    border: none;
    border-radius: 3pt;
    cursor: pointer;
    font-weight: 600;
    letter-spacing: 0.5px;
    background-color: #F35421;
    color: white;
    padding: 14px 28px;
}

.logout:hover {
    background-color: white;
    color: #F35421;
}