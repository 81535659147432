/* -------------------- */
/*GENERAL*/
.gras {
    font-weight: 600;
}

.sub-content {
    min-height: 100vh;
    scroll-snap-align: start;
}

.sub-title {
    font-size: 80px;
    color: #FFC959;
}

.sub-title-2 {
    font-size: 50px;
    color: white;
    text-align: left;
    margin-left: 80px;
}

.para {
    color: white;
    font-weight: 600;
    font-size: 26px;
}

.dot {
    color: #FFC959;
}

.welleat {
    color: #00C897;
}

/* -------------------- */
/*SIMULATION*/

.simulation {
    --sub-content-background: radial-gradient(circle farthest-corner at 50% 220%, rgba(255, 227, 90, 1) 0%, rgba(255, 201, 89, 1) 0%, rgba(43, 43, 42, 1) 72%);

    background: var(--sub-content-background);
    padding-top: 50px;
    color: white;
}

.sim-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    color: white;
    margin-bottom: 60px;
    font-size: 22px;
    font-weight: 600;
    margin-top: 160px;


}

.sim-content-1 {
    display: flex;
    justify-content: center;
}

.sim-content-2 {
    display: flex;
    justify-content: center;
}

.sim-input {
    display: flex;
    width: 600px;

}

.sim-input input {
    height: 34px;
    border-radius: 14pt;
    background-color: #676461;
    border: none;
    margin-top: 18px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 4px;
    font-size: 18px;
    font-weight: 500;
    font-style: italic;
    width: 130px;
    text-align: center;
    color: white;
}

.sim-input input[type=text]:focus {
    outline: none;


}

.input-1 {
    margin-right: 100px;

}

.p-input-1 {
    width: 166px;
    text-align: left;
    padding-left: 120px;

}

.p-input-2 {
    width: 310px;
    text-align: left;
}

.p-sim {
    margin-top: 80px;
    font-size: 20px;
    font-weight: 500;
}

.simButton {
    margin-right: 7%;
}

/* -------------------- */
/*STATS*/


.stats {
    --sub-content-background: radial-gradient(circle farthest-corner at 50% 220%, rgba(255, 227, 90, 1) 0%, rgba(255, 201, 89, 1) 0%, rgba(43, 43, 42, 1) 72%);

    background: var(--sub-content-background);
    padding-top: 50px;
    color: white;
    min-height: 100vh;
}

.stat-number-content {
    display: flex;
    flex-direction: row;
    padding: 0 16%;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 150px;

}

.stat-number {
    width: 25%;
    display: flex;
    flex-direction: column;
    font-weight: 600;
}

.stat-number-para {
    color: white;
    margin-top: -70px;
    font-weight: 400;
    font-size: 19px;
    padding: 0 5%;
}

.pourcent {
    font-size: 96px;
    color: #FFC959;
    font-weight: 700;
}

/* -------------------- */
/*USE*/

.use {
    padding-top: 40px;
}

.users-logo {
    display: flex;
    justify-content: space-around;
    padding-top: 16px;
    padding-left: 30%;
    padding-right: 30%;
}

.users-logo-picture {
    height: 120px;
    width: 120px;
    background-color: white;
    border-radius: 10pt;
    /* background-image: url('/src/assets/img/welleat-logo.png'); */
    background-size: cover;
}

.users-logo-picture-img {
    height: 100%;
    width: 100%;
    border-radius: 10pt;
}

.users-logo-container {
    padding: 0 15%;
}

.users-testi-container {
    padding: 0 20%;
}

.users-testi {
    display: flex;
    justify-content: space-around;
    margin-top: 80px;
    /* padding-left: 25%;
    padding-right: 25%; */
}

.users-testi-container .react-multiple-carousel__arrow--left {
    left: -10px;
}

.users-testi-container .react-multiple-carousel__arrow--right {
    right: -10px;
}

.users-testi-container .react-multiple-carousel__arrow {
    background: rgb(0 0 0 / 0%);
}

.users-testi-container .react-multiple-carousel__arrow:hover {
    background: rgb(0 0 0 / 0%);
}

.users-testi-container .react-multiple-carousel__arrow::before {
    font-size: 40px;
    font-weight: 600;
}

.users-profil {
    /* height: 300px; */
    width: 260px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 30px
}

.profil-picture {
    height: 130px;
    width: 130px;
    background-color: white;
    border-radius: 50%;
    margin-top: 20px;
}

.users-picture {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.users-name {
    text-align: center;
    color: white;
    font-size: 20px;
    font-weight: 700;
    margin-top: 30px;
}

.users-job {
    text-align: center;
    color: white;
    font-size: 14px;
    font-weight: 100;
    margin-top: 4px;
    min-height: 30px;
}

.users-comment {
    text-align: center;
    color: white;
    font-size: 20px;
    font-weight: 200;
    margin-top: 40px;
    font-style: italic;
}

.users-comment::before {
    content: "";
    background-color: #00C897;
    height: 50px;
    width: 50px;
    bottom: 0;
    left: 0;

}

/* -------------------- */
/*CONTACT*/

.contact {

    --sub-content-background: radial-gradient(circle farthest-corner at 50% 220%, rgba(255, 227, 90, 1) 0%, rgba(255, 201, 89, 1) 0%, rgba(43, 43, 42, 1) 72%);

    background: var(--sub-content-background);
    padding-top: 50px;
    color: white;
    padding-top: 40px;
}

.contact-form {
    padding-left: 40%;
}

.form-contact {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.form-contact p {
    width: 100%;
    text-align: right;
    padding-right: 90px;
    font-size: 14px;
    font-weight: 300;
    margin-top: 12px;
}

.form-contact-input {
    display: flex;
    flex-direction: column;
    width: 50%;
    text-align: left;
    margin-bottom: 30px;
}

.form-contact-input input {
    height: 34px;
    border-radius: 14pt;
    background-color: #676461;
    border: none;
    margin-top: 18px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 4px;
    font-size: 18px;
    font-weight: 500;
    font-style: italic;
    width: 80%;
    text-align: left;
    color: white;
}

.form-contact-textarea {
    width: 100%;
    padding-right: 10%;
}

.form-contact-textarea textarea {
    width: 100%;
    border-radius: 18pt;
    background-color: #676461;
    border: none;
    font-size: 18px;
    font-weight: 500;
    font-style: italic;
    text-align: left;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
}

.form-contact-textarea label {
    text-align: left;
    width: 100%;
    display: block;
    font-size: 18px;
    padding-left: 26px;
}

.form-contact-input label {
    font-size: 18px;
    padding-left: 26px;
}

.submit-button {
    width: 100%;
    text-align: right;
    margin-top: 14px;
}

.submit-button button {
    font-size: 18px;
    font-weight: 400;
    color: #FFC959;
    background-color: #32302e;
    border: none;
    padding: 16px 28px;
    border-radius: 12pt;
    cursor: pointer;
}

.sim-button {
    text-align: center;
}

.form-contact-input-newsletter {
    display: flex;
    flex-direction: row;
    width: 100%;
    font-size: 18px;
    padding-left: 26px;
    height: 20px;
}

.form-contact-input-newsletter label {
    margin-right: 50px;
}

.form-contact-input-newsletter-radio-box {
    margin-right: 20px;
    margin-left: 4px;

}

.form-contact-input-newsletter-radio {
    display: flex;
    flex-direction: row;
}

.phone {
    height: 28px;
    width: 100px;
    margin-top: -4px;
    margin-right: 10px;
    margin-left: -4px;
    padding-left: 10px;
    padding-right: 6px;
    border-radius: 10pt;
    background-color: #676461;
    border: none;
    color: white;
    font-style: italic;
}

.phone::placeholder {
    color: white;
    font-style: italic;
}

.submit-button input:hover {
    background-color: #FFC959;
    color: #32302e;
    cursor: pointer;

}

/* -------------------- */
/*STORY*/

.story {
    --sub-content-background: radial-gradient(circle farthest-corner at 50% 220%, rgba(255, 227, 90, 1) 0%, rgba(255, 201, 89, 1) 0%, rgba(43, 43, 42, 1) 72%);

    background: var(--sub-content-background);
    padding-top: 50px;
    padding-bottom: 150px;
}

.story-content {
    padding: 0 19%;
    margin-top: 100px;
    margin-bottom: 50px;

}

/* .line {
    height: 1000px;
    width: 10px;
    background-color: #676461;
} */

.story-content-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: normal;
    background-color: white;
    border-radius: 4pt;
}

.story-content-1 {
    background-color: #FFC959;
    padding: 20px;
    border-top-left-radius: 4pt;
    border-bottom-left-radius: 4pt;

    font-weight: 700;
    font-size: 21px;
}

.story-content-2 {
    background-color: white;
    padding: 22px;
    text-align: left;
    border-top-right-radius: 4pt;
    border-bottom-right-radius: 4pt;
}

.story-last {
    background-color: #fae471;
}



/* -------------------- */
/*DISTRIBUTION*/

.distribution {
    padding: 0px 12% 0 12%;
}

.distrib-margin {
    margin-top: 110px;
}

.distribution h3 {
    color: white;
    font-weight: 800;
    font-size: 28px;
    margin-bottom: 100px;
}



.distribution h4 {
    color: white;
    text-align: left;
    font-weight: 600;
    font-size: 22px;
    margin: 0;
    margin-bottom: 24px;
}

.verticale-line-distrib-1 {
    width: 950px;
    height: 16px;
    background-color: #FFC959;
    margin-top: -112px;
    margin-bottom: 60px;
    margin-left: -6px;
}

.verticale-line-distrib-2 {
    width: 266px;
    height: 16px;
    background-color: #FFC959;
    margin-top: -112px;
    margin-bottom: 60px;
    margin-left: -6px;
}

.distribution ul {
    color: white;
    text-align: left;
}

.distribution-content {
    width: 100%;
    height: 400px;
    display: flex;

}

.distribution-subcontent {
    width: 50%;
    padding: 10px;
}

.distribution-subcontent ul {
    list-style-type: none;
}

.distribution-subcontent li {
    background: url("./../../assets/img/list-square.png");
    padding-left: 3rem;
    background-position: 0 0;
    background-size: 30px 30px;
    background-repeat: no-repeat;
    height: 46px;
    line-height: 30px;

}

.distribution-media {

    height: 100%;
    width: 100%;
}

/* -------------------- */
/*CONSUMER*/

.consumer {
    padding: 80px 10%;
}

/* -------------------- */
/*FOOTER*/


.footer {
    width: 100%;
    height: 300px;
    background-color: black;
    background-color: #f2f2f1;
}

.footer-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #f2f2f1;
}

.footer-quote {
    margin: 50px 0 50px 0;
    font-size: 38px;
    font-style: italic;
    font-weight: 500;
}

.footer-content-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.footer-logo {
    width: 40%;
}

.footerLogoImg {
    width: 150px;
    height: 150px;
}

.footerLogo {
    display: flex;
    justify-content: flex-end;
    margin-right: 28px;
}

.footerLogoFont {
    display: flex;
    justify-content: flex-end;
}

.footerLogoFontImg {
    width: 200px;
    height: 61px;
}

.footer-newsletter {
    width: 60%;
    padding-left: 120px;
}

.footer-newsletter-desc {
    text-align: left;
    margin: 50px 0 24px 0;
    font-size: 18px;
}

.footer-newsletter-content {}

.footer-newsletter-content form {
    display: flex;
    flex-direction: row;
}


.footer-newsletter-input {
    margin: 0 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
}

.footer-newsletter-input label {
    margin-bottom: 10px;

}

.footer-newsletter-input input {
    background-color: darkgrey;
    height: 36px;
    border: none;
    border-radius: 10pt;
    padding: 0 10px;
    color: white;
}

.footer-newsletter-input input[type=text]:focus {
    outline: none;
}

.footer-newsletter-button {
    height: 40px;
    width: 120px;
    padding: 8px 4px;
    background-color: #00C897;
    color: white;
    border-radius: 10pt;
    border: none;
    margin-top: 26px;
    margin-left: 20px;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
}

.footer-newsletter-button:hover {
    color: black;
    background-color: #FFC959;
}

.footer-mentions {
    display: flex;
    align-items: center;
    justify-content: center;

}

.footer-mentions ul {
    justify-content: center;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 40px;
}

.footer-mentions ul li {
    list-style: none;
    margin: 0 10px;
}

.footer-mentions ul li a {
    text-decoration: none;
    color: black;
}

.footer-mentions ul li a:hover {
    color: #00C897;
}

/* --------- */

.product--image {
    width: 100%;
    height: 16em;
    object-fit: cover;
}

.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 320px;
    margin: auto 1rem;
    text-align: center;
    font-family: arial;
}

.price {
    color: grey;
    font-size: 22px;
}

.card button {
    border: none;
    outline: 0;
    padding: 12px;
    color: white;
    background-color: #000;
    text-align: center;
    cursor: pointer;
    width: 100%;
    font-size: 18px;
}

.card button:hover {
    opacity: 0.7;
}